import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import { safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const CommunicationA = () => {
    const { data, heading: Heading } = useContext( PageContext );

    return (
        safetyCheck( data, 'communication' ) && (
            <div>
                <Heading>Communication</Heading>
                <Markdown className="markdown text-sm text-left">{data?.communication}</Markdown>
            </div>
        )
    );
};

export default memo( CommunicationA );
