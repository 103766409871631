import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import PageContext from '../../../contexts/PageContext';
import { Avatar, Group, Stack } from '@mantine/core';
import { formatDateRange, isItemVisible, safetyCheck } from '../../../utils';

const WorkItem = ({ item, language, color }) => {
  const { t } = useTranslation();
  return (
    <div className="no-break print-padding">
      <div className="flex justify-between items-center">
        <div className="flex text-left mr-2">
          <Stack>
          <Group align={"center"} grow={false} noWrap={true} position="left" spacing={"sm"}>
            <Avatar className="work_avatar" size={36} radius="sm" src={item?.logo?.url}>{item?.name.slice(0,1).toUpperCase()}</Avatar>
            <Stack align="flex-start" spacing={0}>
            <h6 className="font-semibold text-sm">{item?.name}</h6>
            <span className="text-xs">{item?.position}</span>
            </Stack>
          </Group>
          
          </Stack>

        </div>
        {item?.start_date && (
          <h6 className="text-xs font-medium text-right">
            (
            {formatDateRange(
              {
                startDate: item?.start_date,
                endDate: item?.end_date,
                language,
              },
              t,
            )}
            )
          </h6>
        )}
      </div>
      {item?.description && (
        <Markdown className="markdown mt-2 text-sm">{item?.description}</Markdown>
      )}
    </div>
  );
};

const WorkA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  return safetyCheck(data, 'experience') ? (
    <div>
      <Heading>Experience</Heading>
      <div className="grid gap-5 mt-4">
        {data?.experience.map(
          (x) =>
            (
              <WorkItem key={x.id} item={x} language={data.metadata.language} color={data.metadata.colors.primary}/>
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(WorkA);
