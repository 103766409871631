import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import { safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const ObjectiveA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  console.log("data: ", data);

  return (
    safetyCheck(data, 'long_description') && (
      <div>
        <Heading>Biography</Heading>
        <Markdown className="markdown text-sm">{data?.long_description}</Markdown>
      </div>
    )
  );
};

export default memo(ObjectiveA);
