import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import PageContext from '../../../contexts/PageContext';
import { Avatar, Group, Stack } from '@mantine/core';
import { formatDateRange, isItemVisible, safetyCheck } from '../../../utils';

const BoardExperienceItem = ({ item, language, color }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="flex text-left mr-2">
          <Stack>
          <Group align={"center"} grow={false} noWrap={true} position="left" spacing={"sm"}>
            <Avatar className="work_avatar" size={36} radius="sm" src={`https://cdn.boardsi.com/board-document/images/companies/${item.id}.jpeg`}>{item.company[0].toUpperCase()}</Avatar>
            <Stack align="flex-start" spacing={0}>
            <h6 className="font-semibold text-sm">{item.company}</h6>
            <span className="text-xs">{item.position}</span>
            </Stack>
          </Group>
          
          </Stack>

        </div>
        {item.startDate && (
          <h6 className="text-xs font-medium text-right">
            (
            {formatDateRange(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language
              },
              t,
            )}
            )
          </h6>
        )}
      </div>
      {item.summary && (
        <Markdown className="markdown mt-2 text-sm">{item.summary}</Markdown>
      )}
    </div>
  );
};

const BoardExperienceA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  return safetyCheck(data.boardExperience) ? (
    <div>
      <Heading>{data.boardExperience.heading}</Heading>
      <div className="grid gap-5 mt-4">
        {data.boardExperience.items.map(
          (x) =>
            isItemVisible(x) && (
              <BoardExperienceItem key={x.id} item={x} language={data.metadata.language} color={data.metadata.colors.primary}/>
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(BoardExperienceA);

