import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const SkillItem = ({ id, skill, level }) => (
  <div key={id} className="flex flex-col">
    <h6 className="font-semibold text-sm">{skill}</h6>
  </div>
);

const SkillsA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data, "skills") ? (
    <div>
      <Heading>Skills</Heading>
      <div className="grid grid-cols-2 gap-y-2 gap-x-4">
        {data?.skills.map((x) => isItemVisible(x) && SkillItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsA);
