import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import { safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const StrategicA = () => {
    const { data, heading: Heading } = useContext( PageContext );

    return (
        safetyCheck( data, 'strategic' ) && (
            <div>
                <Heading>Strategic</Heading>
                <Markdown className="markdown text-sm text-left">{data?.strategic}</Markdown>
            </div>
        )
    );
};

export default memo( StrategicA );
